import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import PageSection from "../components/pageSection"
import TestimonialSection from "../components/testimonialSection"

const HomePage = ({data}) => {
  const Posts = data.pageContent.edges
    .filter(edge => !!edge.node.frontmatter.order)
    .map(edge => <PageSection key={edge.node.id} post={edge.node} />)

  // const random = Math.floor(Math.random() * data.testimonials.edges.length)
  // const Testimonial = <TestimonialSection key={data.testimonials.edges[random].node.id} post={data.testimonials.edges[random].node} />

  const [testimonial, setTestimonial] = useState("")

  useEffect(() => {
    const random = Math.floor(Math.random() * data.testimonials.edges.length)
    setTestimonial(<TestimonialSection key={data.testimonials.edges[random].node.id} post={data.testimonials.edges[random].node} />)
  }, [])

  return (
    <div>
      <Header />
      <div className="content">
        {Posts}
        {testimonial}
      </div>
      <Footer />
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    pageContent: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/home/"} },
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            order
            anchor
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/testimonials/"} },
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            order
            anchor
          }
        }
      }
    }
  }
`
